import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'bootstrap'
import { QrReader } from '@blackbox-vision/react-qr-reader';
import Cookies from 'js-cookie'

export default function ScanApp() {
    const [buyer_code, setBuyerCode] = useState(Cookies.get('buyer_code'));
    const [shutter, openShutter] = useState(null);
    const [toast, setToast] = useState(null);
    const [toast_color, setToastColor] = useState('primary');
    const toastRef = useRef();

    useEffect(() => {
        var myToast = toastRef.current
        var bsToast = new Toast(myToast)
        toast ? bsToast.show() : false
        myToast.addEventListener('hidden.bs.toast', () => {
            setToast(false)
        })
    })

    useEffect(() => {
        Cookies.set('buyer_code', buyer_code,{ expires: 7 })
    }, [buyer_code])

    function callAPI(code) {
        //code = "IEB1193"
        axios.post('api/hostscan/' + code + "/" + buyer_code, { key: 'value' })
            .then(response => {
                //console.log(response.status)
                if (response.status == 200) {
                    setToastColor('primary')
                    setToast("Successfully Scanned!");
                } else {
                    setToastColor('danger')
                    setToast("Status Code: "+response.status);
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 404) {
                        setToastColor('danger')
                        setToast("Invalid Input!");
                    } else if (error.response.status == 500) {
                        setToastColor('danger')
                        setToast("Server Error!");
                    } else {
                        setToastColor('danger')
                        setToast("Config Error!");
                    }
                } else {
                    setToastColor('danger')
                    setToast(error);
                }
            })
    }

    return (
        <div className="container mt-5">
            <div className="d-grid gap-2">
                <span className='btn btn-light btn-lg'>{(buyer_code == "undefined" || buyer_code == null) ? 'No Buyer Assigned' : buyer_code}</span>
                <button className='btn btn-secondary btn-lg' onClick={() => { openShutter("buyer_code") }}>Change Buyer</button>
            </div>
            <div className="d-grid gap-2 fixed-bottom">
                <button className='btn btn-secondary btn-lg' onClick={() => { openShutter("booth_code") }}>Scan Booth</button>
            </div>

            {shutter && <div style={{
                height: '100%',
                width: '100%',
                position: 'fixed',
                top: '0',
                left: '0',
                backgroundColor: 'black',
                zIndex: '10000'
            }}>
                <h1 className='fs-1 text-center text-uppercase text-bg-dark p-3'>scan {shutter}</h1>
                <QrReader
                    scanDelay={250}
                    constraints={{ facingMode: 'environment' }}
                    onResult={(result, error) => {
                        if (!!result) {
                            if (shutter == "buyer_code")
                                setBuyerCode(result?.text);
                            else if (shutter == "booth_code")
                                callAPI(result?.text);
                            openShutter(null)
                        }
                        if (!!error) {
                            //console.info(error);
                        }
                    }}
                />
                <div className="d-grid gap-2 fixed-bottom">
                    <button className='btn btn-secondary btn-lg' onClick={() => { openShutter(null) }}>Close Scanner</button>
                </div>
            </div>}

            <div className="toast-container position-absolute top-50 start-50 translate-middle" id="toastPlacement">
                <div ref={toastRef} className={`toast align-items-center text-white bg-${toast_color} border-0`} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            {toast}
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
    );
}